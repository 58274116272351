const externalConfigs = {
  gtmConfig: () => {
    if (process.env.VUE_APP_TRACKING_ENV === "production") {
      ;(function(w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : ""
        j.defer = true
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
        f.parentNode.insertBefore(j, f)
      })(window, document, "script", "dataLayer", "GTM-KMPP8CV")
    } else {
      window.dataLayer = {
        push: event => console.info("GTM event:", event)
      }
    }
  },
  rakutenConfig: function() {
    ;(function() {
      /*Tracking Bootstrap Set Up DataLayer objects/properties here*/
      if (!window.DataLayer) {
        window.DataLayer = {}
      }
      if (!window.DataLayer.events) {
        window.DataLayer.events = {}
      }
      window.DataLayer.events.SPIVersion =
        window.DataLayer.events.SPIVersion || "3.4.1"
      window.DataLayer.events.SiteSection = "1"
    })()
  }
}

const externalLibs = {
  termly: {
    src:
      "https://app.termly.io/resource-blocker/8d7ea175-8fd2-48b6-a5df-378911a27290",
    async: true,
    inputDefer: true
  },
  rakuten: {
    src: "//tag.rmp.rakuten.com/117966.ct.js",
    async: true,
    inputDefer: true
  },
  twitter: {
    src: "//platform.twitter.com/widgets.js",
    async: true,
    inputDefer: true
  },
  instagram: {
    src: "//www.instagram.com/embed.js",
    async: true,
    inputDefer: true
  },
  yotpo: {
    src:
      "//staticw2.yotpo.com/t7OSK0npvezSSvAUvZaWToUKAQmvmJR234zfh97p/widget.js?preventCookies=true",
    async: true,
    inputDefer: true
  }
}

const adHocImports = {
  greenhouse: {
    src: "https://boards.greenhouse.io/embed/job_board/js?for=bleach",
    containerId: "grnhse_app",
    async: true,
    init: () => {
      window.Grnhse.Iframe.load()
    }
  }
}

let loadedLibs = {}

const createHandler = (libKey, libVal, init = null) => {
  loadedLibs[libKey] = { lib: libVal }
  const eventHandler = loadedLibs[libKey]

  // If we're loading from src, we can listen for onload
  // If not, then the Import object needs to have a 'loaded condition'
  if (libVal.src) {
    eventHandler._onload = () => {
      eventHandler.loaded = true
    }
  } else {
    libVal.loader()
    eventHandler.loaded = () => libVal.loadedCondition()
  }
  if (libVal.unloader) {
    eventHandler.unload = libVal.unloader
  }
  eventHandler.awaitLoad = async () => {
    return new Promise((resolve, reject) => {
      if (eventHandler.loaded) {
        resolve(init)
      } else {
        const interval = setInterval(() => {
          if (!eventHandler.loaded) {
            return
          }
          clearInterval(interval)
          resolve(init)
        }, 100)

        setTimeout(() => {
          clearInterval(interval)
          reject("failed to load lib", libKey)
        }, 10000)
      }
    })
  }
  return eventHandler
}

const appendScript = (eventHandler, { src, async, inputDefer }) => {
  const _options = { capture: false, passive: true }
  if (inputDefer) {
    ;[
      "mousemove",
      "mousedown",
      "keypress",
      "touchstart",
      "touchmove",
      "scroll"
    ].forEach(evt => {
      document.addEventListener(evt, _handleInputEvent, _options)
    })
  } else {
    _doAppend()
  }

  function _handleInputEvent() {
    ;[
      "mousemove",
      "mousedown",
      "keypress",
      "touchstart",
      "touchmove",
      "scroll"
    ].forEach(evt => {
      document.removeEventListener(evt, _handleInputEvent, _options)
    })
    _doAppend()
  }

  function _doAppend() {
    let script = document.createElement("script")
    script.type = "text/javascript"
    script.onload = e => {
      return eventHandler._onload(e)
    }
    script.src = src
    script.async = async
    document.body.appendChild(script)
  }
}

export default {
  loadExternals: () => {
    Object.values(externalConfigs).forEach(func => {
      func()
    })
    Object.entries(externalLibs).forEach(
      ([lib, { src, async, inputDefer }]) => {
        const eventHandler = createHandler(lib, externalLibs[lib])
        appendScript(eventHandler, { src, async, inputDefer })
      }
    )
  },

  loadAdHoc: lib => {
    // If this is a 'custom' snippet, just use the handler to listen for a loaded state (polling)
    if (adHocImports[lib].loader) {
      createHandler(lib, adHocImports[lib])
    } else {
      // Otherwise load and append the script ourselves
      const { src, containerId, async, init } = adHocImports[lib]
      const eventHandler = createHandler(lib, adHocImports[lib], init)
      appendScript(eventHandler, { src, async })
      return containerId
    }
  },

  waitForExternal: async lib => {
    let _interval

    return async function checkForLib() {
      if (loadedLibs[lib]) {
        clearInterval(_interval)
        return loadedLibs[lib].awaitLoad()
      } else if (!_interval) {
        _interval = setInterval(checkForLib(), 500)
      }
    }
  },

  unload: lib => {
    console.log("unload", lib)
    console.log(loadedLibs[lib].unload)
    return loadedLibs[lib].unload()
  }
}
